/** @format */

import React, { useState, useEffect } from "react";
import Navbar from "../navbar/navbar";
import moment from "moment";
import { CiSearch } from "react-icons/ci";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import usePermissions from "../../permissions/permission";
import ExcelJS from "exceljs";
import Cookies from "js-cookie";

const Absence_Report = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(moment().toDate()); // Set current date as default
  const [endDate, setEndDate] = useState(moment().toDate()); // Set current date as default
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const rowsPerPage = 10;
  const { hasPermission } = usePermissions();
  const API_URL = process.env.REACT_APP_FRONTEND_URL;

  const [callingNameQuery, setCallingNameQuery] = useState(""); // For calling name

  const handleFetchData = async () => {
    if (startDate && endDate) {
      setIsLoading(true); // Start loading
      const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
      const formattedEndDate = moment(endDate).format("YYYY-MM-DD");

      const userType = Cookies.get("user_type");
      const supervisorId = Cookies.get("supervisorId");

      let endpoint;

      // Determine the API endpoint based on user type
      if (userType === "superadmin") {
        endpoint = `${API_URL}/v1/hris/attendence/getNotAttend?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      } else if (userType === "admin" && supervisorId) {
        endpoint = `${API_URL}/v1/hris/attendence/getNotAttendBySupervisor?startDate=${formattedStartDate}&endDate=${formattedEndDate}&supervisorId=${supervisorId}`;
      } else {
        console.error("Invalid user type or missing supervisor ID.");
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(endpoint);
        const result = await response.json();

        if (Array.isArray(result)) {
          setData(result); // If the result is already an array
        } else if (result.success && Array.isArray(result.data)) {
          setData(result.data); // If the data is inside result.data
        } else {
          setData([]);
          console.error("Failed to fetch data:", result.message || "Unknown error");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setData([]);
      } finally {
        setIsLoading(false); // Stop loading
      }
    } else {
      alert("Please select both start and end dates.");
    }
  };


  useEffect(() => {
    // Fetch data for the current date on component mount
    handleFetchData();
  }, []);

  const exportToCSV = () => {
    const csvData = [
      ["Date", "Employee Number", "Employee Name", "Department", "Calling Name"], // Column headers
      ...filteredData.map((row) => [
        moment(row.date).format("YYYY-MM-DD"),
        row.employee_no,
        row.employee_fullname,
        row.department,
        row.employee_calling_name || "- -",
      ]),
    ];

    const csv = Papa.unparse(csvData, { skipEmptyLines: true });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "absence_report.csv");
  };
  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Absence Report");

    // Add a header row for the title
    const titleRow = worksheet.addRow(["Absence Report"]);
    titleRow.font = { bold: true, color: { argb: "FFFF0000" }, size: 14 }; // Red color, bold, larger size
    titleRow.alignment = { horizontal: "center", vertical: "middle" }; // Center align
    titleRow.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFFFF00" }, // Yellow background
    };

    // Merge the title row across all columns
    worksheet.mergeCells("A1:E1");

    // Add column headers
    const headerRow = worksheet.addRow([
      "Date",
      "Employee Number",
      "Employee Name",
      "Department",
      "Calling Name",
    ]);
    headerRow.font = { bold: true, size: 12 };
    headerRow.alignment = { horizontal: "center", vertical: "middle" }; // Center align
    headerRow.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFD9E1F2" }, // Light purple background
    };

    // Add data rows
    filteredData.forEach((row) => {
      worksheet.addRow([
        moment(row.date).format("YYYY-MM-DD"),
        row.employee_no,
        row.employee_fullname,
        row.department,
        row.employee_calling_name || "- -",
      ]);
    });

    // Set column widths
    worksheet.columns = [
      { width: 20 },
      { width: 20 },
      { width: 30 },
      { width: 25 },
      { width: 25 },
    ];

    // Generate and save Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "absence_report.xlsx");
  };




  const filteredData = data.filter((row) => {
    const matchesEmployeeNumber = row.employee_no
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesCallingName = row.employee_calling_name
      ?.toLowerCase()
      .includes(callingNameQuery.toLowerCase());
    const matchesDepartment =
      selectedDepartment === "" || row.department === selectedDepartment;

    return matchesEmployeeNumber && matchesCallingName && matchesDepartment;
  });


  const departments = [...new Set(data.map((row) => row.department))];

  const paginatedData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const maxVisiblePages = 5;
  const startPage = Math.max(
    Math.min(
      currentPage - Math.floor(maxVisiblePages / 2),
      totalPages - maxVisiblePages + 1
    ),
    1
  );
  const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="mx-10 mt-5">

      <div className="flex justify-between mt-6">
        <div>
          <p className="text-[30px] font-semibold">Absence Report</p>
        </div>

      </div>

      <div>
        <div className="flex justify-between items-center">
          <div className="flex gap-4 items-center">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                placeholder="Search by Employee Number"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
            </div>


            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                placeholder="Search by Employee Calling Name"
                value={callingNameQuery}
                onChange={(e) => setCallingNameQuery(e.target.value)}
              />
              <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
            </div>

            <div>
              <select
                className=" text-black px-4 py-2 rounded-md shadow-custom"
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e.target.value)}
              >
                <option value="">All Departments</option>
                {departments.map((department, index) => (
                  <option key={index} value={department}>
                    {department}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center space-x-2 bg-white rounded-[20px] px-4 py-2 shadow-sm border border-black">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy-MM-dd"
                className="text-sm text-gray-600 focus:outline-none"
                placeholderText="Start Date"
              />
              <span className="text-gray-400">-</span>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="yyyy-MM-dd"
                className="text-sm text-gray-600 focus:outline-none"
                placeholderText="End Date"
              />
            </div>
            <button
              className="p-3  text-black px-4 py-2 rounded-md shadow-custom"
              onClick={handleFetchData}
            >
              Get Data
            </button>
          </div>

          <div className="relative flex items-center gap-3">
            {hasPermission(3520) && (
              <button
                className="p-3  text-black px-4 py-2 rounded-md shadow-custom"
                onClick={exportToCSV}
              >
                <div className="flex gap-3 items-center">
                  <button onClick={exportToExcel}>Export Excel</button>
                </div>
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="overflow-x-auto mt-5 w-full">
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <p className="text-xl font-bold text-gray-600">
              Loading please wait...
            </p>
          </div>
        ) : (
          <>
            <table className="min-w-full border-collapse">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-700 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-700 uppercase tracking-wider">
                    Employee Number
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-700 uppercase tracking-wider">
                    Employee Name
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-700 uppercase tracking-wider">
                    Calling Name
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-700 uppercase tracking-wider">
                    Department
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-700 uppercase tracking-wider">
                    Remark
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((row, index) => (
                  <tr
                    key={index}
                    className={
                      index % 2 === 0
                        ? "bg-purple-100 text-gray-700"
                        : "bg-white text-gray-700"
                    }
                  >
                    <td className="px-6 py-4 whitespace-nowrap">{row.date}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{row.employee_no}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{row.employee_fullname}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{row.employee_calling_name}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{row.department}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{row.remark}</td>
                  </tr>
                ))}
              </tbody>
            </table>


            {/* Pagination */}
            <div className="flex justify-between items-center py-3">
              <div>
                Showing{" "}
                {paginatedData.length > 0
                  ? (currentPage - 1) * rowsPerPage + 1
                  : 0}{" "}
                to{" "}
                {currentPage * rowsPerPage > filteredData.length
                  ? filteredData.length
                  : currentPage * rowsPerPage}{" "}
                of {filteredData.length} records
              </div>
              <div className="flex space-x-2">
                {/* Previous Button */}
                {startPage > 1 && (
                  <>
                    <button
                      onClick={() => handlePageChange(1)}
                      className="px-3 py-1 border rounded-md"
                    >
                      1
                    </button>
                    {startPage > 2 && <span className="px-3 py-1">...</span>}
                  </>
                )}

                {/* Page Numbers */}
                {Array.from(
                  { length: endPage - startPage + 1 },
                  (_, i) => i + startPage
                ).map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={`px-3 py-1 border rounded-md ${currentPage === page ? "bg-gray-300" : "bg-white"
                      }`}
                  >
                    {page}
                  </button>
                ))}

                {/* Next Button */}
                {endPage < totalPages && (
                  <>
                    {endPage < totalPages - 1 && (
                      <span className="px-3 py-1">...</span>
                    )}
                    <button
                      onClick={() => handlePageChange(totalPages)}
                      className="px-3 py-1 border rounded-md"
                    >
                      {totalPages}
                    </button>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Absence_Report;
