/** @format */

import React, { useState, useEffect } from "react";
import Navbar from "./../../navbar/navbar";
import { MdKeyboardArrowDown } from "react-icons/md";
import Leave_Details from "../../emp_management/dashboard/leave_details";
import { FaBagShopping } from "react-icons/fa6";
import Leave_request_table from "./leave_request_table";
import { CiSearch } from "react-icons/ci";
import { RiMenu5Fill } from "react-icons/ri";
import moment from "moment";
import { TiTick } from "react-icons/ti";
import { IoMdCloseCircleOutline } from "react-icons/io";
import usePermissions from "../../../permissions/permission";
const Leave_Request = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [leaveData, setLeaveData] = useState([]);
  const [selectedDepartment, setSelectedDepartment] =
    useState("All Departments");

  const [searchInput, setSearchInput] = useState("");
  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const { hasPermission } = usePermissions();
  const API_URL = process.env.REACT_APP_FRONTEND_URL;
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleDepartmentSelect = (department) => {
    setSelectedDepartment(department);
    setIsDropdownOpen(false); // Close the dropdown after selection
  };
  // Fetch leave data from the backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/v1/hris/leave/getleave`);
        const data = await response.json();
        console.log("Fetched Data:", data);
        setLeaveData(Array.isArray(data) ? data : []); // Ensure it's an array
      } catch (error) {
        console.error("Error fetching leave data:", error);
      }
    };
    fetchData();
  }, []);
  // Calculate counts
  const receivedLeaveCount = leaveData.length;
  const acceptedLeaveCount = leaveData.filter(
    (leave) => leave.approved_status_1 === "APPROVED"
  ).length;
  const rejectedLeaveCount = leaveData.filter(
    (leave) => leave.approved_status_1 === "REJECTED"
  ).length;
  const handleSearchChange = (e) => {
    console.log({ handleSearchChange });
    setSearchInput(e.target.value);
  };
  return (
    <div className="mx-10 mt-5">
      <div className="mt-6 flex justify-between">
        <div>
          <p className="text-[30px] font-semibold">Leave Request</p>
         
        </div>
      </div>
      <div className="flex justify-between items-center mt-5">
        <div className="grid grid-cols-3 grid-flow-row gap-8 h-[100px] w-[900px]">
          {/* card1 */}
          {hasPermission(3500) && (
            <div className=" p-3 rounded-xl border border-gray-100  bg-white shadow-md">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <FaBagShopping className="w-8 h-8" />
                </div>
                <div>
                  <p className="font-semibold text-green-400">Count</p>
                </div>
              </div>
              <p className="mt-5 font-semibold">Received Leave Requests</p>
              <p className="text-[30px] font-semibold">{receivedLeaveCount}</p>
            </div>
          )}
          {/* card2 */}
          {hasPermission(3501) && (
            <div className=" p-3 rounded-xl border border-gray-100  bg-white shadow-md">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <TiTick className="w-8 h-8" />
                </div>
                <div className="">
                  <p className="font-semibold text-green-400">Count</p>
                </div>
              </div>
              <p className="mt-5 font-semibold">Accepted Leave Requests</p>
              <p className="text-[30px] font-semibold">{acceptedLeaveCount}</p>
            </div>
          )}
          {/* card3 */}
          {hasPermission(3502) && (
            <div className=" p-3 rounded-xl border border-gray-100  bg-white shadow-md">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <IoMdCloseCircleOutline className="w-8 h-8" />
                </div>
                <div className="">
                  <p className="font-semibold text-green-400">Count</p>
                </div>
              </div>
              <p className="mt-5 font-semibold">Rejected Leave Requests</p>
              <p className="text-[30px] font-semibold">{rejectedLeaveCount}</p>
            </div>
          )}
        </div>
       
      </div>

      <div className="mt-[5%]">
        <Leave_request_table searchInput={searchInput} />
      </div>
    </div>
  );
};
export default Leave_Request;
